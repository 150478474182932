.is-boxed {
    background: color-bg(body-outer);

	.body-wrap {
		max-width: $container--width-boxed;
	    margin: 0 auto;
	    box-shadow: color-bg(body-shadow);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			//background: color-bg(body);
			background: #000;
			z-index: -10;
		}
	}
}

.body-wrap {
	position: relative;
    overflow: hidden;
    // Sticky footer
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
    flex: 1 0 auto;
}


.logo {
	width:110px;
	margin-top:-6px;
}

.logo-text {
	position: relative;
    top: -10px;
    left: -12px;
}
