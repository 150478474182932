.feather {
	font-weight: 400;
}
.hero {

	.no-select {
		user-select: none;
	}

.jumbo {
	font-size: 80px;
	line-height: 80px;

	.split-name {
		margin-top: 30px;

		@include media( '<=medium' ) {
			margin-top: 0;
		}
	}

	@include media( '<=medium' ) {
		font-size: 50px;
	}
}

	.paper-garden {
		font-size: 60px;
		margin-bottom: 60px;
		margin-top: 30px;

		@include media( '<=medium' ) {
			font-size: 40px;
		}
	}

	.paper-image {
		content: '';
		position: absolute;
		width: 1940px;
		height: 1280px;
		background-image: url(../../../images/bud/paper.png);
		background-repeat: no-repeat;
		background-size: auto;
		right: -85%;
		transform: rotate3d(11, 11, 6, 322deg);
		background-color: #020202;
		box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #54885d, inset -20px 0 80px #fff, inset 20px 0 300px #518259, inset -20px 0 300px #a1beaf, 0 0 50px #fff, -10px 0 80px #558a5e, 10px 0 80px #f9fdfc;

		&.airdrop {
			box-shadow: none;
		}

		@include media( '<=medium' ) {
			width: 1200px;

		}

	}

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;

		a {
			color: #fff;
		}
	}
}

.hero-inner {

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}


.search {
	display: flex;
	position: relative;
	margin-bottom:40px;
	width: 84%;
	height: 90px;
	//margin-left: 105px;

	.input-placeholder {
		font-weight: 500;
		font-size: 20px;
	}

	input::placeholder {
		font-weight:100 ;
		font-size: 24px;
		color:#000;
	}

	@include media( '<=medium' ) {

		margin-left: auto;
		width: 100%;

		.search-button {
			display: none;
		}

		.input-placeholder {
			font-weight: 500;
		}


	}

	//&::before {
	//	content: "";
	//	position: absolute;
	//	left: 20px;
	//	top: 50%;
	//	transform: translate(0px, -50%);
	//	display: block;
	//	width: 27px;
	//	height: 27px;
	//	background: url(../../../images/misc/search.svg) no-repeat;
	//}

	.search-button {
		position: relative;
		font-size: 28px;
		padding-top: 28px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		left: -21px;
		cursor: pointer;
	}

	.search-button2 {
		background: #000;
		border: 3px solid #31FFA6;
		color: #fff;
	}

	input {
		font-size: 28px;
		outline: none;
		//padding: 20px 0 20px 55px;
		padding: 20px 0 20px 27px;
		border: none;
		font-weight: 100;
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
		@include media( '>=medium' ) {
		min-width: 400px;}

		@include media( '<=medium' ) {
			border-top-right-radius: 12px;
			border-bottom-right-radius: 12px;
			width: 100%;
		}

		@include media( '<=small' ) {
			width: 100%;
			//border-radius: 60px;
		}
	}
}


	.search-button-mobile {
		display:none;

		@include media( '<=medium' ) {
			display: inline-flex;
			justify-content: center;
			margin-top: 25px;
			margin-left:0;
			font-size:24px;

		}

	}

	.search-result {
		background: #060000;
		border-radius: 12px;
		width: 200px;
		margin-left: 43%;
		box-shadow: 0 0 3px;

		@include media( '<=medium' ) {
			margin-left: 113px;
		}

		@include media( '<=small' ) {
			margin-left: 22%;
		}
	}

	.search-result-heading {
		margin-top: 100px;


		@include media( '<=medium' ) {
			margin-left: auto;
			width: auto;
		}
	}

.sub-heading {
	font-size: 29px;
	font-weight: 500;
	color: #9CA9B3;
	line-height: 50px;
}

.garden-text {
	background-image: linear-gradient(131deg, #f6f063, #ff3873 35%, #60ff1e 61%, #3fe5ff 92%);
	background-clip: text;
	color: transparent;

	&:hover {
		background-image: linear-gradient(131deg, #f6f063, #ff3873 35%, #a39f30 61%, #3fe5ff);
	}

}

.garden-text-2 {
	margin-top: 0;
	background-image: linear-gradient(131deg, #f6f063, #ff3873 35%, #60ff1e 61%, #3fe5ff 92%);
	//background-image: linear-gradient(131deg, #f6f063, #2e936f 35%, #60ff1e 61%, #3fe5ff 92%);
	background-clip: text;
	-webkit-text-fill-color: transparent;

	&:hover {
		background-image: linear-gradient(131deg, #f6f063, #ff3873 35%, #575535 61%, #3fe5ff);
	}
}

.garden-btn {
	font-size: 20px;
	text-decoration: underline;
}

.story-text {
	font-size: 20px;
	font-weight: 500;
	line-height: 1.8;
	color:#9CA9B3;

	@include media( '<=small' ) {
		font-size: 20px;
	}
}

.roadmap-title {
	font-size: 90px;
	padding-bottom: 50px;

	@include media( '<=medium' ) {
		font-size: 70px;
	}

	@include media( '<=small' ) {
		font-size: 50px;
	}}

.new {
	display: inline-block;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-padding-start: .25rem;
	padding-inline-start: .25rem;
	-webkit-padding-end: .25rem;
	padding-inline-end: .25rem;
	text-transform: uppercase;
	font-size: 10px;
	border-radius: 0.125rem;
	font-weight: bold;
	background: #C6F6D5;
	color: #22543D;
	margin-left: .25rem;
	height:20px;

}