.button {
    display: inline-flex;
	text-transform: $button--transform;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    letter-spacing: inherit;
    white-space: nowrap;
	transition: background .15s ease;
	//background: linear-gradient(128.17deg, #64a56a -14.78%, #121227 110.05%);
	background: linear-gradient(128.17deg, #31FFA6 -14.78%, #121227 110.05%);
	 padding: 0.25rem 0.75rem;
	 text-decoration: none;
	 color: #fff;
	 border-radius: 12px;
	 font-weight: 500;
	 width: min-content;
	 margin-left: 1rem;
	 border: 1px solid transparent;
	 box-shadow: rgba(0, 0, 0, .1) 0 2px 4px;
	font-size: 20px;

&:active {
        outline: 0;
    }

	&:hover {
		background-color: color-bg(button-light-hover);
		border-color: color-border(button-light-hover);
	}

	&.is-loading {
		position: relative;
		color: transparent!important;
		pointer-events: none;

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: get-line-height(button);
			height: get-line-height(button);
			margin-left: -(get-line-height(button) / 2);
			margin-top: -(get-line-height(button) / 2);
			top: 50%;
			left: 50%;
			border: $button-loading--thickness solid color(button-light);
			border-radius: 50%;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			z-index: 1;
			animation: button-loading .6s infinite linear;
		}
	}

	&[disabled] {
		cursor: not-allowed;
		color: color(button-disabled);
		background-color: color-bg(button-disabled) !important;
		border-color: color-border(button-disabled);

		&.is-loading {

			&::after {
				border-color: color(button-disabled);
			}
		}
	}
}

.button-sm {
	padding: (($button-form-sm--height - get-line-height(button) - ($button-form--border-width * 2)) / 2) ($button-sm--padding-h - $button-form--border-width);
	height: $button-form-sm--height;
}

.button-dark {
	color: color(button-dark);
    background-color: color-bg(button-dark);
	border-color: color-border(button-dark);

    &:hover {
        background-color: color-bg(button-dark-hover);
		border-color: color-border(button-dark-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-dark);
		}
	}
}

.button-primary {
	//color: color(button-primary);
	color: #fff;
    background-color: rgb(100, 165, 106);
	border-color: rgb(100, 165, 106);

    &:hover {
        background-color: rgba(149,197,136,1);
		border-color: rgba(149,197,136,1);
    }

	&.is-loading {

		&::after {
			border-color: rgb(176, 232, 161);
		}
	}
}

.button-secondary {
	color: color(button-secondary);
    background-color: color-bg(button-secondary);
	border-color: color-border(button-secondary);

    &:hover {
        background-color: color-bg(button-secondary-hover);
		border-color: color-border(button-secondary-hover);
    }

	&.is-loading {

		&::after {
			border-color: color(button-secondary);
		}
	}
}

.button-block {
    display: flex;
	width: 100%;
}

.button-group {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-right: -($button-group-padding / 2);
	margin-left: -($button-group-padding / 2);
	margin-top: -($button-group-padding / 2);

	&:last-of-type {
		margin-bottom: -($button-group-padding / 2);
	}

	&:not(:last-of-type) {
		margin-bottom: ($button-group-padding / 2);
	}

	> [class*=button] {
		margin: $button-group-padding / 2;
	}
}

@include media( '<=medium' ) {

	.button-wide-mobile {
		width: 100%;
		padding: .6rem .25rem;
		max-width: $button--max-size-mobile;
		font-size:20px;
	}
}

@keyframes button-loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}


.btn-garden {
	background: linear-gradient(128.17deg, #f6f063 -14.78%, #4b5c44 110.05%);
}
