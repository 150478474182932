/*--------------------------------------------------------------
# Illustrations
--------------------------------------------------------------*/
@import "illustrations/illustrations";

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import "base/fonts";

/*--------------------------------------------------------------
# Scroll reveal
--------------------------------------------------------------*/
@import "base/scroll-reveal";

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import "base/base";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "base/typography";

/*--------------------------------------------------------------
# Containers
--------------------------------------------------------------*/
@import "elements/containers";

/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/
@import "elements/buttons";

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
@import "elements/forms";

/*--------------------------------------------------------------
# Hamburger
--------------------------------------------------------------*/
@import "elements/hamburger";

/*--------------------------------------------------------------
# Modal
--------------------------------------------------------------*/
@import "elements/modal";

/*--------------------------------------------------------------
# Split pattern
--------------------------------------------------------------*/
@import "patterns/split";

/*--------------------------------------------------------------
# Tiles pattern
--------------------------------------------------------------*/
@import "patterns/tiles";

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
@import "layout/header";

/*--------------------------------------------------------------
# Site content
--------------------------------------------------------------*/
@import "layout/main";

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
@import "layout/footer";

/*--------------------------------------------------------------
# Section
--------------------------------------------------------------*/
@import "sections/section";

/*--------------------------------------------------------------
# ShrubPaper
--------------------------------------------------------------*/
@import "sections/hero";

/*--------------------------------------------------------------
# Features split
--------------------------------------------------------------*/
@import "sections/features-split";

/*--------------------------------------------------------------
# Features tiles
--------------------------------------------------------------*/
@import "sections/features-tiles";

/*--------------------------------------------------------------
# Partnerships
--------------------------------------------------------------*/
@import "sections/testimonial";

/*--------------------------------------------------------------
# Call to action
--------------------------------------------------------------*/
@import "sections/cta";

body, footer {
  
  background:#03100D;
  color: #fff;
}

h1, h2 {
  color: #fff;
}
